import logo from './logo.svg';
import './App.css';
import { Widget } from '@typeform/embed-react'
import { createWidget } from '@typeform/embed'
import React, { useState, useEffect } from 'react';
//import '@typeform/embed/build/css/widget.css'


//const tyepform = () => {
//	  return <Widget id="p62oDejO" style={{ width: '50%' }} className="my-form" />
//}


// createWidget('p62oDejO', {
//     container: document.querySelector('#form'), // you need an element with 'form' id
//     hideHeaders: true,
//     hideFooter: true,
//     opacity: 0,
// })


const Survey = () => {
    const [height, setHeight] = useState(window.innerWidth < 620 ? '300px' : '600px');
    
    useEffect(() => {
        function handleResize() {
            setHeight(window.innerWidth < 620 ? '300px' : '600px');
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <Widget
            id='p62oDejO'
            style={{
                width: '60%',
                height: height,
                margin: '20px auto',
            }}
            medium="demo-test"
        />
    );
};




function HomePage() {

    return (
    <div className="App">
	  <header className="App-header">
	  <h2>Illuminate</h2>
      <em>Connecting graduate student tutors and college students, fast.</em>
      
	  <Survey />

      </header>
    </div>
  );
}

export default HomePage;
